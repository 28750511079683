import React, { useEffect, useState } from "react";
import { usePersonalityContext } from "../context/PersonalityContext";
import data from "../data/data.json";
import { Helmet } from "react-helmet-async";

import question1 from "../img/Question 1.png";
import question2 from "../img/Question 2.png";
import question3 from "../img/question-3.gif";
import question4 from "../img/question-4.gif";
import question5 from "../img/Question 5.png";
import question6 from "../img/Question 6.png";
import question7 from "../img/Question 7.png";
import question8 from "../img/question-8.gif";
import question9 from "../img/Question 9.png";
import question10 from "../img/Question 10.png";
import question11 from "../img/question-11.gif";
import question12 from "../img/Question 12.png";
import question13 from "../img/question-13.gif";
import question14 from "../img/question-14.gif";
import question15 from "../img/question-15.gif";
import question16 from "../img/Question 16.png";
import question17 from "../img/question-17.gif";
import question18 from "../img/question 18.png";
import question19 from "../img/question-19.gif";
import question20 from "../img/question-20.gif";

function QuizPage() {
  const { handleAnswer, selectedAnswers } = usePersonalityContext();
  const [questions, setQuestions] = useState([]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    // Shuffle questions when the component mounts
    const shuffledQuestions = shuffleArray([...data]);
    setQuestions(shuffledQuestions);
  }, []);

  const currentQuestion = questions[selectedAnswers.length];

  const progress = (selectedAnswers.length / questions.length) * 100;

  const progressBarStyle = {
    gridTemplateColumns: `repeat(${questions.length}, 1fr)`,
  };

  const questionImages = {
    1: question1,
    2: question2,
    3: question3,
    4: question4,
    5: question5,
    6: question6,
    7: question7,
    8: question8,
    9: question9,
    10: question10,
    11: question11,
    12: question12,
    13: question13,
    14: question14,
    15: question15,
    16: question16,
    17: question17,
    18: question18,
    19: question19,
    20: question20,
  };

  return (
    <section className="quiz">
      <Helmet>
        <meta
          name="description"
          content="Which kind of chess player are you?"
        />
        <title>Chess Personality by Chess.com</title>
      </Helmet>
      <div className="progress-bar">
        <div className="progress-fill" style={{ width: `${progress}%` }}></div>
      </div>
      <div className="progress-bar-grid" style={progressBarStyle}>
        {questions.map((question, index) => (
          <div
            key={index}
            className={`progress-bar-piece ${
              selectedAnswers[index] ? "progress-fill" : ""
            }`}
          ></div>
        ))}
      </div>
      {currentQuestion && (
        <div className="question-container">
          <div className="question">
            <div className="question-text">{currentQuestion.question}</div>
            <div className="answers-container">
              {currentQuestion.answers.map((answer) => (
                <button
                  className="answer"
                  key={answer.text}
                  onClick={() => handleAnswer(answer.score)}
                >
                  <span>{answer.text}</span>
                </button>
              ))}
            </div>
          </div>
          <div className="question-visual">
            <img
              src={questionImages[currentQuestion.id]}
              width="512"
              height="512"
              alt="position"
              className="position"
            />
          </div>
        </div>
      )}
    </section>
  );
}

export default QuizPage;
