import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import results from "../data/results.json";
import data from "../data/data.json";

const PersonalityContext = createContext();

export const usePersonalityContext = () => useContext(PersonalityContext);

export const PersonalityProvider = ({ children }) => {
  const allCharacters = results;
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const navigate = useNavigate();
  const questions = data;

  const handleAnswer = (answer) => {
    setSelectedAnswers([...selectedAnswers, answer]);
  };

  useEffect(() => {
    if (selectedAnswers.length === questions.length) {
      navigate(`/${character.id}`);
    }
  }, [selectedAnswers.length]);

  const calculatePersonality = () => {
    const scores = {
      decision1: {
        intuition: 0,
        calculation: 0,
      },
      decision2: {
        attacking: 0,
        positional: 0,
      },
      decision3: {
        calm: 0,
        emotional: 0,
      },
      decision4: {
        playful: 0,
        studious: 0,
      },
    };

    selectedAnswers.forEach((answer) => {
      switch (answer) {
        case "intuition":
        case "calculation":
          scores.decision1[answer]++;
          break;
        case "attacking":
        case "positional":
          scores.decision2[answer]++;
          break;
        case "calm":
        case "emotional":
          scores.decision3[answer]++;
          break;
        case "playful":
        case "studious":
          scores.decision4[answer]++;
          break;
        default:
          break;
      }
    });

    const highestScores = {
      decision1:
        scores.decision1.intuition > scores.decision1.calculation
          ? "intuition"
          : "calculation",
      decision2:
        scores.decision2.attacking > scores.decision2.positional
          ? "attacking"
          : "positional",
      decision3:
        scores.decision3.calm > scores.decision3.emotional
          ? "calm"
          : "emotional",
      decision4:
        scores.decision4.playful > scores.decision4.studious
          ? "playful"
          : "studious",
    };

    const highestScoresArray = Object.values(highestScores);

    const character = allCharacters.find((character) =>
      highestScoresArray.every(
        (decision, index) => character.highScores[index] === decision
      )
    );

    return { scores, character };
  };

  const { scores, character } = calculatePersonality();

  return (
    <PersonalityContext.Provider
      value={{
        scores,
        character,
        handleAnswer,
        selectedAnswers,
      }}
    >
      {children}
    </PersonalityContext.Provider>
  );
};
