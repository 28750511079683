import React from "react";

import inventorGraphic from "../img/inventor-graphic.png";

function Personalities() {
  return (
    <section className="personalities">
      <h2>Discover Every Chess Personality</h2>
      <div className="items-grid">
        <a href="/anaconda">
          <div className="item">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/18987.4d5b82bc.png"
              alt="anaconda"
              width="181"
              height="181"
            />
          </div>
        </a>

        <a href="/artist">
          <div className="item">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/19055.1a501dcf.png"
              alt="artist"
              width="181"
              height="181"
            />
          </div>
        </a>

        <a href="/assassin">
          <div className="item">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/19553.c13f07d1.png"
              alt="Assassin"
              width="181"
              height="181"
            />
          </div>
        </a>

        <a href="/caveman">
          <div className="item">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/19057.36c20d68.png"
              alt="caveman"
              width="181"
              height="181"
            />
          </div>
        </a>

        <a href="/grappler">
          <div className="item">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/19557.6fda7b6d.png"
              alt="grappler"
              width="181"
              height="181"
            />
          </div>
        </a>

        <a href="/inventor">
          <div className="item">
            <img
              src={inventorGraphic}
              alt="inventor"
              width="181"
              height="181"
            />
          </div>
        </a>

        <a href="/jester">
          <div className="item">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/19065.66463dd3.png"
              alt="Jester"
              width="181"
              height="181"
            />
          </div>
        </a>

        <a href="/machine">
          <div className="item">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/19067.c8e3a88d.png"
              alt="Machine"
              width="181"
              height="181"
            />
          </div>
        </a>

        <a href="/madscientist">
          <div className="item">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/19069.74615998.png"
              alt="mad scientist"
              width="181"
              height="181"
            />
          </div>
        </a>

        <a href="/magician">
          <div className="item">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/19071.6c273af0.png"
              alt="Magician"
              width="181"
              height="181"
            />
          </div>
        </a>

        <a href="/mastermind">
          <div className="item">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/19073.7cb2010b.png"
              alt="Mastermind"
              width="181"
              height="181"
            />
          </div>
        </a>

        <a href="/professor">
          <div className="item">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/19555.2454b2d9.png"
              alt="Professor"
              width="181"
              height="181"
            />
          </div>
        </a>

        <a href="/romantic">
          <div className="item">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/19077.1b9da96d.png"
              alt="Romantic"
              width="181"
              height="181"
            />
          </div>
        </a>

        <a href="/swindler">
          <div className="item">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/19079.575ec5c1.png"
              alt="Swindler"
              width="181"
              height="181"
            />
          </div>
        </a>

        <a href="/technician">
          <div className="item">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/19559.c145d5f7.png"
              alt="Technician"
              width="181"
              height="181"
            />
          </div>
        </a>

        <a href="/wildcard">
          <div className="item">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/19561.7f010879.png"
              alt="Wildcard"
              width="181"
              height="181"
            />
          </div>
        </a>
      </div>
    </section>
  );
}

export default Personalities;
