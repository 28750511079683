import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PersonalityProvider } from "./context/PersonalityContext";
import WelcomePage from "./pages/WelcomePage";
import QuizPage from "./pages/QuizPage";
import ResultPage from "./pages/ResultPage";
import CookieConsent from "react-cookie-consent";
import "./App.css";
import { HelmetProvider } from "react-helmet-async";

import question1 from "./img/Question 1.png";
import question2 from "./img/Question 2.png";
import question3 from "./img/question-3.gif";
import question4 from "./img/question-4.gif";
import question5 from "./img/Question 5.png";
import question6 from "./img/Question 6.png";
import question7 from "./img/Question 7.png";
import question8 from "./img/question-8.gif";
import question9 from "./img/Question 9.png";
import question10 from "./img/Question 10.png";
import question11 from "./img/question-11.gif";
import question12 from "./img/Question 12.png";
import question13 from "./img/question-13.gif";
import question14 from "./img/question-14.gif";
import question15 from "./img/question-15.gif";
import question16 from "./img/Question 16.png";
import question17 from "./img/question-17.gif";
import question18 from "./img/question 18.png";
import question19 from "./img/question-19.gif";
import question20 from "./img/question-20.gif";

function App() {
  useEffect(() => {
    const preloadImages = () => {
      const imageUrls = [
        question1,
        question2,
        question3,
        question4,
        question5,
        question6,
        question7,
        question8,
        question9,
        question10,
        question11,
        question12,
        question13,
        question14,
        question15,
        question16,
        question17,
        question18,
        question19,
        question20,
      ];

      imageUrls.forEach((url) => {
        const img = new Image();
        img.src = url;
      });
    };

    preloadImages();
  }, []);
  return (
    <HelmetProvider>
      <div className="main-container">
        <header className="header">
          <div
            onClick={() => (window.location.href = "/")}
            style={{ cursor: "pointer" }}
          >
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/18977.0ccdd4f9.png"
              height="32"
              width="248"
              alt="personality logo"
            />
          </div>
          <img
            src="https://images.chesscomfiles.com/uploads/ad_image/18975.aebc8b82.png"
            height="20"
            width="77"
            alt="chess.com logo"
            className="chesscom-logo"
          />
        </header>
        <Router>
          <PersonalityProvider>
            <Routes>
              <Route path="/" element={<WelcomePage />} />
              <Route path="/quiz" element={<QuizPage />} />
              <Route path="/:characterId" element={<ResultPage />} />
            </Routes>
          </PersonalityProvider>
        </Router>
        <CookieConsent
          location="bottom"
          buttonText="I understand"
          cookieName="CookieConsent"
          style={{
            background: "#1E1D1A",
            color: "rgba(255, 255, 255, 0.50)",
          }}
          buttonStyle={{
            background: "#81B64C",
            color: "#fff",
            fontSize: "14px",
            borderRadius: "3px",
            padding: "8px 16px",
            textWrap: "nowrap",
          }}
          expires={150}
          containerClasses="cookies-container"
          contentClasses="cookies-content"
        >
          This website stores and accesses information on your device, such as
          cookies.
        </CookieConsent>
        <footer className="footer">
          <div className="logos-container">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/18977.0ccdd4f9.png"
              height="32"
              width="248"
              alt="personality logo"
            />
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/18975.aebc8b82.png"
              height="20"
              width="77"
              alt="chess.com logo"
            />
          </div>
          <nav className="nav-container">
            <a
              href="https://old.chesspersonality.com/"
              target="_blank"
              rel="noreferrer"
              className="nav-item"
            >
              Looking for the old quiz?
            </a>
            <a href="https://www.chess.com/about" className="nav-item">
              About
            </a>
            <a href="https://www.chess.com/legal/privacy" className="nav-item">
              Privacy
            </a>
            <a
              href="https://www.chess.com/article/view/digital-services-act-compliance?expose=0"
              className="nav-item"
            >
              Compliance
            </a>
            <a href="https://www.chess.com/" className="nav-item">
              Chess.com © 2024
            </a>
          </nav>
        </footer>
      </div>
    </HelmetProvider>
  );
}

export default App;
