import React from "react";
import { Link, useLocation } from "react-router-dom";
import Personalities from "../components/Personalities";
import { Helmet } from "react-helmet-async";

function WelcomePage() {
  function scrollToTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
  let location = useLocation();
  let canonicalUrl = `${window.location.origin}${location.pathname}`;

  return (
    <main>
      <Helmet>
        <meta
          name="description"
          content="Take a chess personality quiz to discover the best chess openings for your style, which chess master you look like, and which friends are most like you."
        />
        <title>
          Chess Personality Test: What Kind of Chess Player Are You?
        </title>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <section className="hero-wrapper">
        <div className="hero">
          <div className="hero-text">
            <h1>Which kind of chess player are you?</h1>
            <div className="cta-logo-container">
              <Link to="/quiz" className="btn-primary">
                Take the Quiz
              </Link>
              <div className="mobile-only chesscom-logo-color">
                <img
                  src="https://images.chesscomfiles.com/uploads/ad_image/19157.8d19a99b.png"
                  alt="chesscom logo"
                  width="109"
                  height="24"
                />
              </div>
            </div>
          </div>
          <div className="hero-img">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/18979.3f62f720.png"
              alt="icons"
              height="493"
              width="512"
            />
          </div>
        </div>
      </section>
      <section className="info">
        <h2>Answer 20 Questions and Find Out…</h2>
        <div className="cards-container">
          <div className="card">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/18983.38d80bb8.png"
              alt="two siluets of people"
              width="95"
              height="96"
              className="siluet-img"
            />
            <p className="card-text">Your chess personality</p>
          </div>
          <div className="card">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/18981.60f9ee3c.png"
              alt="chess pieces in the book"
              width="118"
              height="96"
              className="book-img"
            />
            <p className="card-text">Best openings for your style</p>
          </div>
          <div className="card">
            <img
              src="https://images.chesscomfiles.com/uploads/ad_image/18985.38244823.png"
              alt="queen"
              width="103"
              height="96"
              className="queen-img"
            />
            <p className="card-text">Which masters you are most like</p>
          </div>
        </div>
      </section>
      <Personalities />
      <div className="cta-button mobile-only">
        <Link to="/quiz" className="btn-primary" onClick={() => scrollToTop()}>
          Take the Quiz
        </Link>
      </div>
    </main>
  );
}

export default WelcomePage;
