import React, { useState } from "react";
import { usePersonalityContext } from "../context/PersonalityContext";
// import "./Share.css";

function Share() {
  const { character } = usePersonalityContext();
  let url = window.location.href;
  let shareMessage = character.shareText;
  let whatsappUrl = `whatsapp://send?text=${encodeURIComponent(
    shareMessage
  )} ${encodeURIComponent(url)}`;

  let [copySuccess, setCopySuccess] = useState("");
  let copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };
  return (
    <>
      {/* Facebook */}
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${url}&hashtag=${shareMessage}`}
        target="_blank"
        rel="noreferrer"
        className="share-button"
      >
        <img
          src="https://images.chesscomfiles.com/uploads/ad_image/19007.e6e4d316.png"
          width="24"
          height="24"
          alt="share facebook"
        />
      </a>

      {/* Twitter */}
      <a
        href={`https://twitter.com/intent/tweet?url=${url}&text=${encodeURI(
          shareMessage
        )}`}
        rel="noreferrer"
        target="_blank"
        className="share-button"
      >
        <img
          src="https://images.chesscomfiles.com/uploads/ad_image/19011.e5f9c591.png"
          width="20"
          height="18"
          alt="share twitter"
        />
      </a>

      <a
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&text=${shareMessage}`}
        rel="noreferrer"
        target="_blank"
        className="share-button"
      >
        <img
          src="https://images.chesscomfiles.com/uploads/ad_image/19009.32ab7c3d.png"
          width="24"
          height="24"
          alt="share linkedin"
        />
      </a>
      <a
        href={whatsappUrl}
        data-action="share/whatsapp/share"
        rel="noreferrer"
        target="_blank"
        className="share-button"
      >
        <img
          src="https://images.chesscomfiles.com/uploads/ad_image/19005.6e6d93b1.png"
          width="24"
          height="24"
          alt="share sms"
        />
      </a>
      <a
        href={`https://telegram.me/share/url?url=${url}&text=${shareMessage}`}
        rel="noreferrer"
        target="_blank"
        className="share-button"
      >
        <img
          src="https://images.chesscomfiles.com/uploads/ad_image/19001.0d135822.png"
          width="24"
          height="24"
          alt="share on telegram"
        />
      </a>
      <button
        onClick={() => copyToClipBoard(shareMessage + url)}
        className="share-button copy-link"
      >
        <img
          src="https://images.chesscomfiles.com/uploads/ad_image/19003.b977f49a.png"
          width="24"
          height="24"
          alt="share link"
        />
        {copySuccess && <span className="copy-success">{copySuccess}</span>}
      </button>
    </>
  );
}

export default Share;
